<script>
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useAlert } from 'dashboard/composables';
import router from '../../../../index';
import axios from 'axios';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

export default {
  template: `
    <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: v$.inboxName.$error }">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
            @blur="v$.inboxName.$touch"
          />
          <span v-if="v$.inboxName.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: v$.phoneNumber.$error }">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL') }}
          <input
            v-model.trim="phoneNumber"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')"
            @blur="v$.phoneNumber.$touch"
          />
          <span v-if="v$.phoneNumber.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
          </span>
        </label>
      </div>

      <!-- Campos ocultos -->
      <div style="display: none;">
        <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
          <label :class="{ error: v$.phoneNumberId.$error }">
            <span>
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.LABEL') }}
            </span>
            <input
              v-model.trim="phoneNumberId"
              type="text"
              :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.PLACEHOLDER')"
              @blur="v$.phoneNumberId.$touch"
            />
            <span v-if="v$.phoneNumberId.$error" class="message">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.ERROR') }}
            </span>
          </label>
        </div>

        <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
          <label :class="{ error: v$.businessAccountId.$error }">
            <span>
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.LABEL') }}
            </span>
            <input
              v-model.trim="businessAccountId"
              type="text"
              :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.PLACEHOLDER')"
              @blur="v$.businessAccountId.$touch"
            />
            <span v-if="v$.businessAccountId.$error" class="message">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.ERROR') }}
            </span>
          </label>
        </div>

        <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
          <label :class="{ error: v$.apiKey.$error }">
            <span>{{ $t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.LABEL') }}</span>
            <input
              v-model.trim="apiKey"
              type="text"
              :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.PLACEHOLDER')"
              @blur="v$.apiKey.$touch"
            />
            <span v-if="v$.apiKey.$error" class="message">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.ERROR') }}
            </span>
          </label>
        </div>
      </div>

      <!-- Botão de conexão com WhatsApp -->
      <div class="w-full">
        <button class="btn-whatsapp" type="button" @click="handleConnect" :disabled="!isAuthorized">
          Connect with WhatsApp
        </button>
        <!-- Exibir a mensagem de erro apenas quando a verificação estiver completa -->
        <p v-if="verificationComplete && !isAuthorized" class="text-red-500 mt-2">
          Licença inválida ou expirada, procure 
          <a href="https://wa.me/5517996418016" class="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
            +55 17 99641-8016
          </a>
        </p>
      </div>

      <!-- Botão de envio -->
      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
          :disabled="!isFormValid"
        />
      </div>
    </form>
  `,
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      uiFlags: 'inboxes/getUIFlags',
    }),
    isFormValid() {
      return this.apiKey && this.phoneNumberId && this.businessAccountId;
    },
  },
  data() {
    return {
      inboxName: '',
      phoneNumber: '',
      apiKey: '',
      phoneNumberId: '',
      businessAccountId: '',
      isAuthorized: false,
      verificationComplete: false,
      licenseKey: null, // Inicializamos como null
      appId: null, // Inicializamos como null
      configId: null, // Inicializamos como null
      token: null, // Inicializamos como null
    };
  },
  validations() {
    return {
      inboxName: { required },
      phoneNumber: { required },
      apiKey: { required },
      phoneNumberId: { required },
      businessAccountId: { required },
    };
  },
  created() {
    // Definimos as variáveis a partir de `globalConfig` no `created`
    this.licenseKey = this.globalConfig.whatsappEmbeddedLicenseKey;
    this.appId = this.globalConfig.whatsappEmbeddedFacebookAppId;
    this.configId = this.globalConfig.whatsappEmbeddedFacebookConfigId;
    this.token = this.globalConfig.whatsappEmbeddedFacebookToken;
    console.log('licenseKey:', this.licenseKey);
    console.log('appId:', this.appId);
    console.log('configId:', this.configId);
    console.log('token:', this.token);
  },
  async mounted() {
    try {
      const currentUrl = window.location.href;
      const currentDomain = new URL(currentUrl).hostname;
      const authorized = await this.fetchAuthorizedLicense(currentDomain);

      if (authorized) {
        this.isAuthorized = true;
      }
      this.verificationComplete = true;
    } catch (error) {
      this.isAuthorized = false;
      this.verificationComplete = true;
    }

    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    document.body.appendChild(script);

    window.fbAsyncInit = () => {
      window.FB.init({
        appId: this.appId,
        cookie: true,
        xfbml: true,
        version: 'v20.0',
      });
    };

    ((d, s, id) => {
      let js = d.getElementById(id);
      const fjs = d.getElementsByTagName(s)[0];
      if (js) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    window.addEventListener('message', this.sessionInfoListener);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.sessionInfoListener);
  },
  methods: {
    async createChannel() {
      this.v$.$touch();
      if (this.v$.$invalid || !this.isAuthorized) {
        console.log("Form validation failed or URL do sistema não autorizada ou licença inválida");
        return;
      }

      try {
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            channel: {
              type: 'whatsapp',
              phone_number: this.phoneNumber,
              provider: 'whatsapp_cloud',
              provider_config: {
                api_key: this.apiKey,
                phone_number_id: this.phoneNumberId,
                business_account_id: this.businessAccountId,
              },
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        useAlert(error.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'));
      }
    },
    async sessionInfoListener(event) {
      if (
        event.origin !== 'https://www.facebook.com' &&
        event.origin !== 'https://web.facebook.com'
      ) {
        console.warn("Event origin not allowed:", event.origin);
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP' && data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
          this.phoneNumberId = phone_number_id;
          this.businessAccountId = waba_id;
          this.apiKey = this.token;
          await this.registerWaba();

          const response = await axios.get(
            `https://graph.facebook.com/v20.0/${this.phoneNumberId}`,
            {
              headers: { Authorization: `Bearer ${this.token}` },
            }
          );

          this.phoneNumber = response.data.display_phone_number.replace(/[\s-]/g, '');
          this.inboxName = response.data.verified_name;
        }
      } catch (error) {
      }
    },
    handleConnect() {
      this.launchWhatsAppSignup();
    },
    async registerWaba() {
      if (!this.phoneNumberId || !this.businessAccountId || !this.token) {
        console.warn("Missing required information for registering WABA");
        return;
      }

      try {
        await axios.post(
          `https://graph.facebook.com/v20.0/${this.phoneNumberId}/register`,
          {
            messaging_product: 'whatsapp',
            pin: '123456',
          },
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );

        await axios.post(
          `https://graph.facebook.com/v20.0/${this.businessAccountId}/subscribed_apps`,
          {},
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );
        console.log("WABA registered successfully");
      } catch (error) {
        console.error("Error registering WABA:", error);
      }
    },
    launchWhatsAppSignup() {
      if (window.fbq) {
        window.fbq('trackCustom', 'WhatsAppOnboardingStart', {
          appId: this.appId,
          feature: 'whatsapp_embedded_signup',
        });
      }

      window.FB.login(() => {}, {
        config_id: this.configId,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          feature: 'whatsapp_embedded_signup',
          sessionInfoVersion: 2,
        },
      });
    },
    async fetchAuthorizedLicense(currentDomain) {
      try {
        const response = await axios.post(
          'https://authenticatorcwcode.renv.com.br/verificador.php',
          new URLSearchParams({
            domain: currentDomain,
            license: this.licenseKey,
          }).toString(),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        if (response.data && response.data.token) {
          return true;
        } else {
          throw new Error(response.data.message || 'Licença ou domínio inválido.');
        }
      } catch (error) {
        await this.sendUsageNotification(currentDomain, this.licenseKey);
        return false;
      }
    },
    async sendUsageNotification(domain, license) {
      try {
        const response = await axios.post('https://authenticatorcwcode.renv.com.br/usage-notification.php', {
          domain,
          license,
          timestamp: new Date().toISOString(),
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        if (response.data.success) {
          console.log("Notificação de uso enviada com sucesso");
        } else {
          console.log("Falha ao enviar notificação de uso");
        }
      } catch (error) {
        console.log("Erro ao enviar notificação de uso:", error);
      }
    },
  },
};
</script>

<style scoped>
.btn-whatsapp {
  background-color: #25d366;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
}

.btn-whatsapp:hover {
  background-color: #128c7e;
}
</style>
